import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.footer`
  width: 100%;
  padding: 32px;

  background: #3f3e39;
  color: rgba(255, 255, 255, 0.7);
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  section {
    display: flex;
    justify-content: space-between;

    padding: 72px 0;
    img {
      max-height: 64px;
    }

    article {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      span {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        svg {
          :nth-of-type(1) {
            margin-left: 8px;
          }
        }
      }
    }

    h3 {
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
      color: rgba(255, 255, 255, 0.4);
    }

    @media (max-width: 940px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      article {
        align-items: center;
        margin-bottom: 64px;
      }
    }

    @media (max-width: 440px) {
      article:last-child {
        margin-bottom: 16px;
        p {
          font-size: 14px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    @media (max-width: 385px) {
      article:last-child {
        p {
          font-size: 11px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    p {
      margin-right: 16px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 32px;
      }
    }

    @media (max-width: 1190px) {
      flex-direction: column;
      justify-content: center;

      a {
        margin-top: 20px;
      }

      p {
        text-align: center;
      }
    }

    @media (max-width: 400px) {
      p {
        font-size: 12px;
      }

      img {
        height: 28px;
      }
    }
  }
`;

export const Links = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  :hover {
    color: #fff112;
    cursor: pointer;
  }

  p:hover {
    color: #fff112;
    cursor: pointer;
  }
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
  }

  @media (max-width: 860px) {
    img {
      width: 160px;
    }
  }
`;

export const WrapperSocial = styled.div``;
