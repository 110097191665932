import React from "react";

import whatsLogo from "../../images/whats.svg";

import { Container } from "./styles";

const Whats = () => {
  return (
    <Container>
      <a href="https://api.whatsapp.com/send?phone=5567999503857&text=Ol%C3%A1%20preciso%20de%20um%20or%C3%A7amento">
        <img
          src={whatsLogo}
          alt="WhatsApp"
          title="(67) 99950-3857 - Fale direto no WhatsApp"
        />
      </a>
    </Container>
  );
};

export default Whats;
