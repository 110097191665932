import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 47px;
  height: 47px;
  border-radius: 50%;

  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

  :hover {
    cursor: pointer;
  }

  img {
    width: 52px;
    height: 52px;
  }
`;
