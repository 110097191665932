import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";

import zamp from "../../images/zamp.png";
import logo from "../../images/logobranco.png";

import { Container, Content, Links } from "./styles";

const Footer = () => {
  return (
    <Container>
      <Content>
        <section>
          <article>
            <Links to="/">
              <img src={logo} alt="LOGO SF SOLAR" />
            </Links>
            <span>
              <a
                href="https://www.instagram.com/sfempresasolar/"
                title="@sfempresasolar"
              >
                <AiOutlineInstagram size={32} />
              </a>
            </span>
          </article>
          <article>
            <h3>Institucional</h3>
            <Links to="/">
              <p>Home</p>
            </Links>
            <Links to="/quemsomos/">
              <p>A SF Solar</p>
            </Links>
            <Links to="/contato/">
              <p>Contato</p>
            </Links>
            <Links to="/orcamento/">
              <p>Solicite um Orçamento</p>
            </Links>
          </article>
          <article>
            <h3>Contato</h3>
            <p>Av. Antônio Maria Coelho, 1472 - Centro</p>
            <p>Campo Grande-MS - CEP 79002-220</p>
            <p>Telefones: (67) 99950-3857 / (67) 99685-2943</p>
            <p>e-mail: contato@sfsolar.com.br</p>
          </article>
        </section>
        <div>
          <p>
            SF Solar Soluções em Energia &copy; 2020. Todos os direitos
            reservados
          </p>
          <a href="https://www.zamp.tech">
            <p>Desenvolvido por:</p>
            <img src={zamp} alt="Logo Zamp Tecnologia" />
          </a>
        </div>
      </Content>
    </Container>
  );
};

export default Footer;
