import React from "react";

import GlobalStyles from "../../styles/global";
import Whats from "../WhatsIconFixed";

import { Container } from "./styles";

const Layout = ({ children }) => {
  return (
    <Container>
      <GlobalStyles />
      <Whats />
      {children}
    </Container>
  );
};
export default Layout;
